import React from 'react';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { ContentSafeArea } from '../../components/globalComponents';
import { PageTitle, ImageTextContainer, ImageContainer, ImageText } from './styles';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

export default function Sobre() {

  const { machine } = useStaticQuery(graphql`
    query {
      machine: file(name: {eq: "TargaXT"}, extension: {eq: "jpeg"}) {
        childImageSharp {
          fluid(maxHeight: 350) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Sobre" />
      <ContentSafeArea>
        <PageTitle> Sobre a Vivagraf </PageTitle>
        <p style={{textAlign: 'justify'}}>
          A <strong>Comunicação Visual</strong> é um dos fatores principais para o sucesso de uma empresa.
          Fazer com que sua marca seja vista é a melhor forma de atrair o seu público.
          A <strong>Vivagraf</strong> está no mercado há <strong>23 anos</strong> fazendo com que centenas de marcas
          sejam vistas todos os dias.
        </p>
        <ImageTextContainer>
          <ImageContainer>
            <Img
              fluid={machine.childImageSharp.fluid}
              fadeIn={true}
            />
          </ImageContainer>
          <ImageText>
            Nossa missão é simplificar a <strong>divulgação do seu negócio</strong>, por isso cuidamos de todo o processo,
            desde a <strong>criação da arte</strong> até a <strong>instalação</strong>.
            Temos os equipamentos da mais alta tecnologia e a equipe mais capacitada, por isso entregamos a mais <strong>alta
            qualidade</strong> e <strong>durabilidade</strong> em nossos produtos, e tudo isso em <strong>velocidade recorde</strong>.
          </ImageText>
        </ImageTextContainer>
        <p style={{textAlign: 'justify'}}>
          Nosso parque industrial conta com uma área construída de mais de <strong>2.000m²</strong> para que possamos realizar tudo que
          sua empresa precisa. Contamos com equipes especializadas de design, impressão, serralheria, acabamento e
          instalação, para que cada etapa da produção do seu produto esteja nas mãos dos melhores profissionais.
        </p>
      </ContentSafeArea>
    </Layout>
  )
}
