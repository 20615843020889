import styled from 'styled-components';

export const PageTitle = styled.h1`
  margin-top: 2rem;
`

export const ImageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media(min-width: 801px) {
    flex-direction: row;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  margin: 1rem;

  @media(min-width: 801px) {
    width: 50%;
  }
`;

export const ImageText = styled.p`
  width: 100%;
  text-align: justify;

  @media(min-width: 801px) {
    width: 50%;
  }
`;
